import request from "../utils/request";

export const find = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "browseArticles/user/find",
    method: "get",
    params: query,
  });
};

export const findlist = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "browseArticles/find/list",
    method: "get",
    params: query,
  });
};

export const findcurrent = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "browseArticles/find/current",
    method: "get",
    params: query,
  });
};

export const giveSet = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "Article/user/give/set",
    method: "post",
    data: query,
  });
};

export const sendComment = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "Article/user/comment/send",
    method: "post",
    data: query,
  });
};

export const findComment = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "Article/user/comment/find",
    method: "get",
    params: query,
  });
};

//查询文章标题（模糊）
export const findRegex = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "browseArticles/find/regex",
    method: "get",
    params: query,
  });
};

//查询热点
export const findHot = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "browseArticles/find/hot",
    method: "get",
    params: query,
  });
};

export const Stoke = (query) => {
  return request({
    url: "http://wx.ariutaiaa.com/test/ticket",
    method: "post",
    data: query,
  });
};
