<template>
  <div class="fuiler">
    <div class="nav_block">
      <Header class="Header"></Header>
      <div class="block">
        <div class="NavTitle"></div>
        是一场基于交叉学科背景的群体智慧实验，通过区块链技术保障群体协作的版
        权及效益分配，让“万人”共同创作文学作品。每季将会有一个主题，参与人可自行组队在限时日期内完成挑战，即可获得丰厚奖励。
      </div>
    </div>

    <div class="main">
      <div class="black">
        <div class="block" @click="black">返回</div>
      </div>
      <div class="router">首页>>当季主题>>{{ Title }}>>正文</div>
      <div class="data">
        <div class="title">{{ data.Title }}</div>
        <div class="text" v-html="data.content"></div>
        <div class="vc"></div>
      </div>

      <!--      <div class="comment">-->
      <!--        <div class="number">0条评论</div>-->
      <!--        <div class="coData">-->
      <!--          <div class="block">-->
      <!--            <div class="ico"></div>-->
      <!--            <div class="name">用户名：XXXXX</div>-->
      <!--            <div class="name">2021年1月1日</div>-->
      <!--          </div>-->
      <!--          <div class="lisk">请发表您的看法</div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <Footer class="Footer"></Footer>
  </div>
</template>

<script>
import Header from "../conponents/Header";
import Footer from "../conponents/Footer";
import { findcurrent } from "../../../api/browseArticles";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      Title: "",
      data: {},
    };
  },
  mounted() {
    let query = this.$route.query;
    let Title = query.Title;
    let _id = query._id;
    this.Title = Title;
    findcurrent({ _id }).then((res) => {
      let data = res.sum[0];
      let text = data.content.replace(/<p>/g, "<p style='text-indent: 1rem'>");
      data.content = text;

      this.data = data;
    });
  },
  methods: {
    black() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.fuiler {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100% 100%;

  .nav_block {
    position: relative;
    background-image: url("../../../assets/Image/public/bg.png");
    width: 100%;
    height: 11rem;
    margin-bottom: 5rem;

    .Header {
      background-image: url("../../../assets/Image/public/header.png");
      background-size: 100% 100%;
      height: 4rem;
    }

    @mixin nav_block {
      position: absolute;
      width: 500px;
      height: 100px;
      padding: 0.8rem;
      background-color: #ffffff;
      border-radius: 15px;
      box-shadow: 0 0 15px 1px #78432e;
      font-size: 13px;
      line-height: 26px;
      top: 6rem;
      text-indent: 1rem;
    }

    .block {
      left: 50%;
      margin-left: -250px;
      @include nav_block;
    }

    .NavTitle {
      background-image: url("../../../assets/Image/public/NavTitle.png");
      background-size: 100% 100%;
      width: 7rem;
      height: 2rem;
      display: inline-block;
    }
  }

  .main {
    width: 1200px;
    margin: 0 auto;
    min-height: 1000px;
    .black {
      width: 1000px;
      margin: 0 auto;
      height: 2rem;
      margin-top: 50px;
      margin-bottom: 50px;
      position: relative;
      .browse {
        position: absolute;
        left: 1.9rem;
        font-size: 0.8rem;
        top: 0.5rem;

        a {
          color: #78432e;
          text-decoration: underline;
        }
      }
      .block {
        position: absolute;
        right: 1.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        padding: 0.5rem 1.5rem;
        background-color: #93523a;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .router {
      font-size: 16px;
      color: #666;
      width: 100%;
      text-indent: 1rem;
      margin-bottom: 50px;
    }
    .data {
      width: 1000px;
      margin: 0 auto;
      .title {
        font-size: 1.2rem;
        font-weight: bold;
      }
      .text {
        font-size: 30px;
        line-height: 60px;
      }
    }
    .vc {
      border-bottom: 1px solid #936c5b;
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
    }

    .comment {
      width: 1200px;
      margin: 0 auto;
      .number {
        font-size: 0.5rem;
        color: #666;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 30px;
      }
      .coData {
        margin-bottom: 2rem;
        .block {
          width: 100%;
          min-height: 50px;
          position: relative;
          margin: 0 auto;
          margin-bottom: 30px;
          .ico {
            background-color: #93523a;
            border-radius: 10rem;
            width: 0.5rem;
            height: 0.5rem;
            display: inline-block;
            position: absolute;
            left: -0.8rem;
            top: 0.5rem;
          }
          .name {
            margin-left: 0.5rem;
            color: #666666;
            font-size: 0.5rem;
            margin-bottom: 0.4rem;
          }
        }
        .lisk {
          background-color: #f2f2f2;
          width: 100%;
          height: 40px;
          line-height: 40px;
          border-radius: 0.5rem;
          font-size: 0.5rem;
          text-indent: 1rem;
          margin-top: 50px;
        }
      }
    }
  }

  .Footer {
    background-image: url("../../../assets/Image/public/header.png");
    background-size: 100% 100%;
  }
  img {
    width: 100%;
  }
}
</style>
